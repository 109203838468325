import React, { Fragment } from "react";
import ConsultorioVirtual from "./components/ConsultorioVirtual";
import { GlobalStyles } from "./components/GlobalStyles.style";
import "./App.css";

//Code
function App() {
  return (
    <Fragment>
      <GlobalStyles />
        <ConsultorioVirtual />
    </Fragment>
  );
}

export default App;
