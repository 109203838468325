import React, { Fragment } from "react";
import styled from "styled-components";
import { useForm } from "../hooks/useForm";
import Loader from "./Loader";
import Message from "./Message";
import { FaMobileAlt, FaEnvelope, FaWhatsapp } from "react-icons/fa";
//import {Link as LinkR} from 'react-router-dom';
import ContactImg from "../images/STMContacto.svg";

//Styles
const ContactFormContainer = styled.div`
    -youbkit-touch-callout: none; /*iOS Safari*/
    -youbkit-user-select: none;   /* Chrome 6.0+, Safari 3.1+, Edge y Opera 15+ */
    -moz-user-select: none;    /*Firefox*/
    -ms-user-select: none;        /* IE 10+ y Edge */
    user-select: none;            /* Versión sin prefijo,
                                actualmente compatible con Chrome y Opera */
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#fff" : "#8c8c8c")};

  @media screen and (max-width: 1366px) {
    height: auto;
  }

  @media screen and (max-width: 768px) {
    height: 1300px;
  }

  @media screen and (max-width: 480px) {
    height: auto;
  }
`;

const ContactFormH1Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 24px;
`;

const ContactFormH1 = styled.h1`
  font-size: 2.5rem;
  color: #fff;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

const ContactFormPWrapper = styled.div`
  max-width: 1000px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0;
  padding-left: 24px;
  padding-right: 24px;
`;

const ContactFormP = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  padding: 0 15px;
  padding-bottom: 24px;
`;

const ContactFormWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: auto;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  align-items: center;
  justify-content: center;
`;

const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1 col1' 'col2 col2'` : `'col1 col1' 'col2 col2'`};
  }


`;


const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

const ContacFormForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
`;

const ContactFormInput = styled.input`
  border: thin solid #dedede;
  border-radius: 0.25rem;
  padding: 0.75rem;
  margin-bottom: 1rem;
  outline: none;
  display: block;
  width: 100%;
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  color: #fff;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #dcdcdc;
  }
  :-ms-input-placeholder {
    color: #dcdcdc;
  }
`;

const ContactFormTextarea = styled.textarea`
  resize: none;
  border: thin solid #dedede;
  border-radius: 0.25rem;
  padding: 0.75rem;
  margin-bottom: 1rem;
  outline: none;
  display: block;
  width: 100%;
  font-size: 1rem;
  line-height: 2;
  background-color: transparent;
  color: #fff;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #dcdcdc;
  }
  :ms-input-placeholder {
    color: #dcdcdc;
  }
`;

const ContactFormInputSend = styled.input`
  width: 30%;
  border-radius: 50px;
  background: #01bf71;
  white-space: nowrap;
  padding: 14px 48px;
  color: #010606;
  font-size: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
  }
`;

const ContactFormMobileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const MobileIcon = styled.div`
  color: #fff;
  font-size: 50px;
`;

const MobileLink = styled.a`
  color: #fff;
  font-size: 100px;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 24px;
  padding-right: 10px;

  &:hover {
    color: #01bf71;
    transition: 0.3s ease-out;
  }
`;

const ContactFormWhatsAppWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const WhatsAppIcon = styled.div`
  color: #fff;
  font-size: 50px;
`;

const WhatsAppLink = styled.a`
  color: #fff;
  font-size: 100px;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 24px;
  padding-right: 10px;

  &:hover {
    color: #01bf71;
    transition: 0.3s ease-out;
  }
`;


const ContactFormEmailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const EmailIcon = styled.div`
  color: #fff;
  font-size: 50px;
`;

const EmailLink = styled.a`
  color: #fff;
  font-size: 100px;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 18px;
  padding-right: 10px;
// se baja el font size por que genera overflow que no se puede cortar y hace aparecer scroll bar horizontal que mueve todo
  &:hover {
    color: #01bf71;
    transition: 0.3s ease-out;
  }
`;

const ImgWrap = styled.div`
  max-width: 555px;
height: 50%;
`;

const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
  pointer-events: none;
  object-fit: contain;
`;

//Code

const initialForm = {
  name: "",
  email: "",
  subject: "",
  comments: "",
};

const validationsForm = (form) => {
  let errors = {};
  let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
  let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
  let regexComments = /^.{1,255}$/;

  if (!form.name.trim()) {
    errors.name = `El campo "Nombre" es requerido`;
  } else if (!regexName.test(form.name.trim())) {
    errors.name = `El campo "Nombre" sólo acepta letras y espacios en blanco`;
  }

  if (!form.email.trim()) {
    errors.email = `El campo "Email" es requerido`;
  } else if (!regexEmail.test(form.email.trim())) {
    errors.email = `El campo "Email" es incorrecto`;
  }

  if (!form.subject.trim()) {
    errors.subject = `El campo "Asunto" es requerido`;
  }

  if (!form.comments.trim()) {
    errors.comments = `El campo "Comentarios" es requerido`;
  } else if (!regexComments.test(form.comments.trim())) {
    errors.comments = `El campo "Comentarios" no debe exceder los 255 caracteres`;
  }

  return errors;
};

const ContactForm = () => {
  const {
    form,
    errors,
    loading,
    response,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm(initialForm, validationsForm);

  return (
    <Fragment>
      <ContactFormContainer lightBg={false} id="contact">
        <ContactFormH1Wrapper>
          <ContactFormH1>Contáctame</ContactFormH1>
        </ContactFormH1Wrapper>
        <ContactFormPWrapper>
          <ContactFormP>
            Puedes ponerte en contacto con nosotros y conocer nuestras tarifas, a través del siguiente formulario
            de contacto, o si prefieres puedes llamar y agendar tu cita, también
            puedes escribirnos un WhatsApp o email y te responderemos en el menor
            tiempo posible.
          </ContactFormP>
        </ContactFormPWrapper>
        <ContactFormWrapper>
          <InfoRow imgStart={true}>
            <Column1>
              <ContacFormForm onSubmit={handleSubmit}>
                <ContactFormInput
                  type="text"
                  name="name"
                  placeholder="Escribe tu nombre"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={form.name}
                  required
                />
                {errors.name && <p>{errors.name}</p>}

                <ContactFormInput
                  type="email"
                  name="email"
                  placeholder="Escribe tu email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={form.email}
                  required
                />
                {errors.email && <p>{errors.email}</p>}

                <ContactFormInput
                  type="text"
                  name="subject"
                  placeholder="Asunto a tratar"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={form.subject}
                  required
                />
                {errors.subject && <p>{errors.subject}</p>}

                <ContactFormTextarea
                  name="comments"
                  cols="50"
                  rows="5"
                  placeholder="Escribe tus comentarios"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={form.comments}
                  required
                />

                {errors.comments && <p>{errors.comments}</p>}

                <ContactFormInputSend type="submit" value="Enviar" />

                {loading && <Loader />}
                {response && (
                  <Message
                    msg="Los datos han sido enviados"
                    bgColor="#198754"
                  />
                )}
              </ContacFormForm>
	      <ContactFormMobileWrapper>
		<MobileIcon>
                <MobileLink href="tel:+573156535795">
		  <FaMobileAlt size={50}/>
		  </MobileLink>
		</MobileIcon>
                <MobileLink href="tel:+573156535795">
                  +57 315 6535795
                </MobileLink>
	      </ContactFormMobileWrapper>
    <ContactFormWhatsAppWrapper>
		<WhatsAppIcon>
                <WhatsAppLink href="https://wa.me/573156535795" target="_blank">
		  <FaWhatsapp size={50}/>
		  </WhatsAppLink>
		</WhatsAppIcon>
                <WhatsAppLink href="https://wa.me/573156535795" target="_blank">
                  +57 315 6535795
                </WhatsAppLink>
              </ContactFormWhatsAppWrapper>
              <ContactFormEmailWrapper>
		<EmailIcon>
                <EmailLink href="mailto:sanatumente.org@gmail.com">
		  <FaEnvelope size={50} />
		  </EmailLink>
                </EmailIcon>
                <EmailLink href="mailto:sanatumente.org@gmail.com">
                  sanatumente.org@gmail.com
                </EmailLink>
              </ContactFormEmailWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={ContactImg} alt="img-contact" />
                {/*<Img src={require('../images/Contact.jpg').default} alt='img-contact'/>*/}
              </ImgWrap>
            </Column2>
          </InfoRow>
        </ContactFormWrapper>
      </ContactFormContainer>
    </Fragment>
  );
};

export default ContactForm;
