import React, { Fragment } from "react";
import styled from "styled-components";
import ProfileImg from "../images/STMProfile.svg";

//Styles
const InfoContainer = styled.div`
    -youbkit-touch-callout: none; /*iOS Safari*/
    -youbkit-user-select: none;   /* Chrome 6.0+, Safari 3.1+, Edge y Opera 15+ */
    -moz-user-select: none;    /*Firefox*/
    -ms-user-select: none;        /* IE 10+ y Edge */
    user-select: none;            /* Versión sin prefijo,
                                actualmente compatible con Chrome y Opera */
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#fff" : "#8c8c8c")};

  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 100vh;
  // height:860px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;

 @media screen and (max-width: 1366px) {
    height: auto;
  }

  @media screen and (max-width: 768px) {
    height: auto;
  }
`;

const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
  text-align: center;
`;

const Heading = styled.h1`
  margin-bottom: 24px;
  padding-top:24px;
  font-size: 48;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

const TextContent = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
`;

const ImgWrap = styled.div`
  max-width: 555px;
  height: 50%;
`;

const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
  pointer-events: none;
  object-fit: contain;
`;

//Code
const ProfileSection = () => {
  return (
    <Fragment>
      <InfoContainer lightBg={false} id="profile">
        <InfoWrapper>
          <InfoRow imgStart={false}>
            <Column1>
              <TextWrapper>
                <Heading lightText={true}>Conóceme</Heading>
                <TextContent darkText={false}>
                  Hola, soy Yuly Vigoya, psicóloga titulada y acreditada por el
                  Colegio Colombiano de Psicología, por un tiempo prolongado me
                  he desempeñado en el área clínica, principalmente en la
                  atención individual, familiar y de pareja, me caracterizo por
                  ser empática, establecer vínculos de confianza y tener una
                  escucha activa, así como la aplicación en mi trabajo de
                  técnicas humanistas y teorías innovadoras, que se ajusten a la
                  necesidad de cada reto personal y a cada diagnóstico mental,
                  de esta manera he podido orientar a las personas que se han
                  acercado a mí, para que generen herramientas asertivas que
                  contribuyan al manejo adecuado de sus emociones y la
                  resolución de los conflictos que están dificultando su
                  funcionalidad y bienestar día a día.
                </TextContent>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img
                  src={ProfileImg}
		  alt="img-profile"
                />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </Fragment>
  );
};

export default ProfileSection;
