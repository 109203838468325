import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { FiPlus, FiMinus } from "react-icons/fi";

//Styles
const FAQContainer = styled.div`
    -youbkit-touch-callout: none; /*iOS Safari*/
    -youbkit-user-select: none;   /* Chrome 6.0+, Safari 3.1+, Edge y Opera 15+ */
    -moz-user-select: none;    /*Firefox*/
    -ms-user-select: none;        /* IE 10+ y Edge */
    user-select: none;            /* Versión sin prefijo,
                                actualmente compatible con Chrome y Opera */
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#fff" : "#8c8c8c")};

  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

const FAQWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  height: auto;
  //Se cambia de 100vh a auto para mantener el crecimiento al abrir preguntas
  // height: 860px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  align-items: center;
  justify-content: center;

  //Para el tamaño del portatil
  @media screen and (max-width: 1366px) {
    height: auto;
  }
`;

const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};
  padding-top: 24px;

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 1000px;
  position: relative;
  height: 100%;
  background: ${({ lightBg }) => (lightBg ? "#fff" : "#8c8c8c")};
`;

const Container = styled.div`
  background: ${({ lightBg }) => (lightBg ? "#fff" : "#8c8c8c")};

  @media screen and (min-width: 768px) {
    width: 768px;
  }

  // box-shadow: 2px 10px 35px 1px rgba(153, 153, 153, 0);
`;

const Wrap = styled.div`
  background: ${({ lightBg }) => (lightBg ? "#fff" : "#8c8c8c")};
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  text-align: justify;
  cursor: pointer;
  padding-right: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #00ffb9;
  border-top: 1px solid #00ffb9;
  padding: 2rem;
`;

const Myh1 = styled.h1`
  font-size: 18px;
  line-height: 24px;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};
`;

const Myspan = styled.span`
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};
  padding-left: 10px;
  font-size: 24px;
`;

const Dropdown = styled.div`
  background: ${({ lightBg }) => (lightBg ? "#fff" : "#8c8c8c")};
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  border-bottom: 1px solid #01bf71;
  border-top: 1px solid #01bf71;
  padding: 2rem;
  font-size: 18px;
  line-height: 24px;
`;

//Code

const FAQSection = () => {
  const Data = [
    {
      question: "¿Mi información esta segura en Sana tu Mente?",
      answer:
        "Sí, la información queda entre tu terapeuta y tú, ya que el código deontológico - ley 1090 de 2006 de la ley colombiana, exige a la profesión de psicología el cumplimiento de la confidencialidad, por esta razón tus datos personales e historia clínica están seguros.",
    },
    {
      question: "¿Cuando tomar terapia?",
      answer:
        "Debemos prestar atención a signos de alarma como: cambio en las emociones, dificultad para tomar decisiones, reducción de la capacidad para dormir o adaptarnos al entorno, cambios significativos en el comportamiento u otros; sin embargo, también es importante tener en cuenta que ir a terapia psicológica nos ayuda a sentir mejor,  por esta razón, no debes  esperar a tener dificultades o problemas graves. Algunos de los grandes beneficios de la terapia es permitirnos liberar cargas emocionales, la adquisición de herramientas para el crecimiento personal, el autoreconocimiento entre otros. De esta manera, siempre es un buen momento para asistir a terapia y buscar nuestro bienestar mental.",
    },
    {
      question: "¿Cuantas sesiones de terapia necesito?",
      answer:
        "Cada persona es diferente, por este motivo se evalúan características individuales que se asocian con el motivo de la consulta, la problemática, su capacidad de resolución de conflictos, disposición para la autorreflexión, entre otras. Es decir, cada persona necesita un número diferente de intervenciones.",
    },
    {
      question: "¿La psicóloga cumple con los requisitos legales?",
      answer:
        "En Sana tu Mente, estamos en contra del intrusismo profesional, por esta razón, te garantizo que cuento con la titulación requerida por la ley colombiana, así como, la tarjeta profesional e inscripción ante entes de salud, para ejercer como psicóloga.",
    },
    {
      question: "¿Como puedo empezar una sesión?",
      answer:
        "Ponte en contacto con nosotros, a través de nuestros canales (formulario de contacto, llamada, chat o correo), luego, juntos agendaremos el mejor momento para tu sesión terapéutica.",
    },
    {
      question: "¿Puedo cancelar una sesion?",
      answer:
      "Si por alguna incidencia tuvieras que cancelar una sesion, ponte en contacto con nosotros y buscaremos la mejor solucion, es muy importante que nos avises con almenos 2 horas de anterioridad.",
    },
  ];

  const [clicked, setCliked] = useState(false);

  const toggle = (index) => {
    if (clicked === index) {
      //if cliked question is alredy active, then close it
      return setCliked(null);
    }

    setCliked(index);
  };

  return (
    <Fragment>
      <FAQContainer lightBg={true} id="faq">
        <FAQWrapper>
          <Heading>Preguntas Frecuentes</Heading>
          <AccordionSection lightBg={true}>
            <Container>
              {Data.map((item, index) => {
                return (
                  <Fragment>
                    <Wrap
                      onClick={() => toggle(index)}
                      key={index}
                      lightBg={true}
                    >
                      <Myh1 lightText={false}>{item.question}</Myh1>
                      <Myspan lightText={false}>
                        {clicked === index ? <FiMinus /> : <FiPlus />}
                      </Myspan>
                    </Wrap>
                    {clicked === index ? (
                      <Dropdown lightBg={true} lightText={false}>
                        <p>{item.answer}</p>
                      </Dropdown>
                    ) : null}
                  </Fragment>
                );
              })}
            </Container>
          </AccordionSection>
        </FAQWrapper>
      </FAQContainer>
    </Fragment>
  );
};

export default FAQSection;
