import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import styled from "styled-components";
import Home from "../pages/Home";
import Error404 from "../pages/Error404";
//import SignIn from "../pages/SignIn";

//Styles
// const MyDiv = styled.div`
//   margin: 1rem;
// `;

// const MyH1 = styled.h1`
//   text-align: center;
// `;

// const MyButton = styled.button`
//   border-radius: 0.5rem;
// `;

//Code
const ConsultorioVirtual = () => {
  return (
    <Fragment>
      <Router>
	<Routes>
	  <Route path="/" element={<Home/>} />
	  <Route path="*" element={<Error404/>} />
	  {/*<Route path="/signin" element={<SignIn/>} />*/}
	</Routes>
	{/* <Home/> */}
      {/* <MyDiv> */}
	{/* <MyH1> Consultorio Virtual</MyH1> */}
	{/* <br /> */}
	{/* <h2>Primera parte de la página</h2> */}
	{/* <MyButton>Prueba boton estilizado</MyButton> */}
      {/* </MyDiv> */}
      </Router>
    </Fragment>
  );
};

export default ConsultorioVirtual;
