import React, { Fragment } from "react";
import PageNotFound from "../components/PageNotFound";

//Styles

//Code
const Error404 = () => {
  return (
    <Fragment>
      <PageNotFound />
    </Fragment>
  );
};

export default Error404;
