import React, { useState, Fragment } from "react";
import HeroSection from "../components/HeroSection";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Services from "../components/Services";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";
import AboutSection from "../components/AboutSection";
import ProfileSection from "../components/ProfileSection";
import FAQSection from "../components/FAQSection";
import DisclaimerSection from "../components/DisclaimerSection";

//Styles

//Code
const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Fragment>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <AboutSection />
      <ProfileSection />
      <Services />
      <ContactForm />
      <FAQSection />
      <DisclaimerSection />
	<Footer />
    </Fragment>
  );
};

export default Home;
