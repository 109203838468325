import React, { Fragment } from "react";
import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  // FaYoutube,
} from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import LogoSanaTuMenteBlanco from "../images/sana-tu-mente-blanco.svg";

//Styles
const FooterContainer = styled.footer`
  -youbkit-touch-callout: none; /*iOS Safari*/
  -youbkit-user-select: none; /* Chrome 6.0+, Safari 3.1+, Edge y Opera 15+ */
  -moz-user-select: none; /*Firefox*/
  -ms-user-select: none; /* IE 10+ y Edge */
  user-select: none; /* Versión sin prefijo,
                                actualmente compatible con Chrome y Opera */
  background-color: #101522;
`;

const FooterWrap = styled.div`
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: auto;
`;

const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
`;

const FooterLinksWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;

const FooterLinkTitle = styled.h1`
  font-size: 14px;
  margin-bottom: 16px;
`;

// const FooterLink = styled(LinkR)`
//   color: #fff;
//   text-decoration: none;
//   margin-bottom: 0.5rem;
//   font-size: 14px;

//   &:hover {
//     color: #01bf71;
//     transition: 0.3s ease-out;
//   }
// `;

const FooterLinkS = styled(LinkS)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover {
    color: #01bf71;
    transition: 0.3s ease-out;
  }
`;

const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`;

const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 40px auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

// const SocialLogo = styled(LinkR)`
//   color: #fff;
//   justify-self: start;
//   cursor: pointer;
//   text-decoration: none;
//   font-size: 1.5rem;
//   display: flex;
//   align-items: center;
//   margin-bottom: 16px;
//   font-weight: bold;
// `;

const WebsiteRights = styled.small`
  color: #fff;
  margin-bottom: 16px;
  text-align: center;
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
`;

const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
`;

const FooterLogoSanaTuMenteBlanco = styled.img`
  margin: 15px;
  height: 40px;
  pointer-events: none;
`;

const FooterLogoWrapper = styled.div`
  display: flex;
`;

const FooterLogoLinkWrapper = styled(LinkR)`
  display: flex;
  //   align-items: center;
  //   height: 10px;
  //   width: 10px;
  //   margin:10px;
  //   font-size: 1.5rem;
`;

const MobileLink = styled.a`
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  margin-bottom: 0.5rem;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover {
    color: #01bf71;
    transition: 0.3s ease-out;
  }
`;

const EmailLink = styled.a`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover {
    color: #01bf71;
    transition: 0.3s ease-out;
  }
`;

//Code
const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <Fragment>
      <FooterContainer>
        <FooterWrap>
          <FooterLinksContainer>
            <FooterLinksWrapper>
              <FooterLinkItems>
                <FooterLinkTitle>Acerca de Sana tu mente</FooterLinkTitle>
                <FooterLinkS
                  href="#about"
                  to="about"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  ¿Que es Sana tu mente?
                </FooterLinkS>
                <FooterLinkS
                  href="#profile"
                  to="profile"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Perfil
                </FooterLinkS>
                <FooterLinkS
                  href="#faq"
                  to="faq"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Preguntas Frecuentes
                </FooterLinkS>
              </FooterLinkItems>
              <FooterLinkItems>
                <FooterLinkTitle>Contáctame</FooterLinkTitle>
                <FooterLinkS
                  href="#contact"
                  to="contact"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Formas de contacto
                </FooterLinkS>
                <EmailLink href="mailto:sanatumente.org@gmail.com">
                  sanatumente.org@gmail.com
                </EmailLink>
                <MobileLink href="tel:+573156535795">
                  +57 315 6535795
                </MobileLink>
              </FooterLinkItems>
            </FooterLinksWrapper>
            <FooterLinksWrapper>
              {/*<FooterLinkItems>
                <FooterLinkTitle>Redes Sociales</FooterLinkTitle>
                <FooterLink to="/">Instagram</FooterLink>
                <FooterLink to="/">Twitter</FooterLink>
                <FooterLink to="/">Facebook</FooterLink>
                <FooterLink to="/">Youtube</FooterLink>
	      </FooterLinkItems>*/}
            </FooterLinksWrapper>
          </FooterLinksContainer>
          <SocialMedia>
            <SocialMediaWrap>
              <FooterLogoWrapper>
                <FooterLogoLinkWrapper to="/" onClick={toggleHome}>
                  <FooterLogoSanaTuMenteBlanco
                    src={LogoSanaTuMenteBlanco}
                    alt={"logo-sana-tu-mente"}
                  />
                </FooterLogoLinkWrapper>
              </FooterLogoWrapper>
              {/*<SocialLogo to="/" onClick={toggleHome}>
                Sana tu mente
	      </SocialLogo>*/}
              <WebsiteRights>
                Sana tu mente © {new Date().getFullYear()} todos los derechos
                reservados.
              </WebsiteRights>
              <SocialIcons>
                <SocialIconLink
                  href="https://www.facebook.com/sanatumenteorg"
                  target="_blank"
                  rel="noopener"
                  arial-label="Facebook"
                >
                  <FaFacebook />
                </SocialIconLink>
                <SocialIconLink
                  href="https://www.instagram.com/sanatumenteorg"
                  target="_blank"
                  rel="noopener"
                  arial-label="Instagram"
                >
                  <FaInstagram />
                </SocialIconLink>
                {/*<SocialIconLink href="/" target="_blank" arial-label="Youtube">
                  <FaYoutube />
		</SocialIconLink>*/}
                <SocialIconLink
                  href="https://twitter.com/sanatumenteorg"
                  target="_blank"
                  rel="noopener"
                  arial-label="Twitter"
                >
                  <FaTwitter />
                </SocialIconLink>
                <SocialIconLink
                  href="https://www.linkedin.com/company/sanatumenteorg"
                  target="_blank"
                  rel="noopener"
                  arial-label="Linkedin"
                >
                  <FaLinkedin />
                </SocialIconLink>
              </SocialIcons>
            </SocialMediaWrap>
          </SocialMedia>
        </FooterWrap>
      </FooterContainer>
    </Fragment>
  );
};

export default Footer;
