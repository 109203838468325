import React, { Fragment } from "react";
import styled from "styled-components";

//Styles
const DisclaimerContainer = styled.div`
  
    -youbkit-touch-callout: none; /*iOS Safari*/
    -youbkit-user-select: none;   /* Chrome 6.0+, Safari 3.1+, Edge y Opera 15+ */
    -moz-user-select: none;    /*Firefox*/
    -ms-user-select: none;        /* IE 10+ y Edge */
    user-select: none;            /* Versión sin prefijo,
                                actualmente compatible con Chrome y Opera */
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#fff" : "#8c8c8c")};

  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

const DisclaimerWrapper = styled.div`
  display: flex;
  z-index: 1;
  height: 17vh;
  // height:860px;
  width: 100%;
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 24px;
  padding-right: 24px;
  align-items: center;
  text-align: justify;
  justify-content: center;
`;

const DisclaimerP = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  padding: 0 15px;
  
`;

//Code
const DisclaimerSection = () => {
  return (
    <Fragment>
      <DisclaimerContainer lightBg={false} id="profile">
        <DisclaimerWrapper>
          <DisclaimerP>
            Importante: Los servicios disponibles a través de Sana tu Mente son
            proporcionados de forma independiente por un profesional en salud
            mental certificado, quien no pre-escribe medicamentos a través de
            Sana tu Mente. Si estás experimentando una crisis o emergencia, por
            favor comunícate a los servicios de emergencia más cercanos a tu
            localidad.
          </DisclaimerP>
        </DisclaimerWrapper>
      </DisclaimerContainer>
    </Fragment>
  );
};

export default DisclaimerSection;
