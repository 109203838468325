import { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";
import LogoSanaTuMente from "../images/sana-tu-mente.svg";

//Styles
const Nav = styled.nav`
    -youbkit-touch-callout: none; /*iOS Safari*/
    -youbkit-user-select: none;   /* Chrome 6.0+, Safari 3.1+, Edge y Opera 15+ */
    -moz-user-select: none;    /*Firefox*/
    -ms-user-select: none;        /* IE 10+ y Edge */
    user-select: none;            /* Versión sin prefijo,
                                actualmente compatible con Chrome y Opera */

  background: ${({ scrollNav }) => (scrollNav ? "#45cccc" : "transparent")};
  // background: #6699cc;
  height: 80px;
  width: 100%;
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`;

// const NavLogo = styled(LinkR)`
//   color: #fff;
//   justify-self: flex-start;
//   cursor: pointer;
//   font-size: 1.5rem;
//   display: flex;
//   align-items: center;
//   margin-left: 24px;
//   font-weight: bold;
//   text-decoration: none;
// `;

const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
`;

const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NavItem = styled.li`
  height: 80px;
`;

const NavLinks = styled(LinkS)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid #01bf71;
  }
`;

// const NavBtn = styled.nav`
//   display: flex;
//   align-items: center;

//   @media screen and (max-width: 768px) {
//     display: none;
//   }
// `;

// const NavBtnLink = styled(LinkR)`
//   border-radius: 50px;
//   background: #01bf71;
//   white-space: nowrap;
//   padding: 10px 22px;
//   color: #010606;
//   font-size: 16px;
//   outline: none;
//   border: none;
//   cursor: pointer;
//   transition: all 0.2s easy-in-out;
//   text-decoration: none;

//   &:hover {
//     transition: all 0.2s ease-in-out;
//     background: #fff;
//     color: #010606;
//   }
// `;

const NavLogoSanaTuMente = styled.img`
  margin: 15px;
  pointer-events:none;
`;

const NavLogoWrapper = styled.div`
  display: flex;
`;

const NavLogoLinkWrapper = styled(LinkR)`
  display: flex;
  //   align-items: center;
  //   height: 10px;
  //   width: 10px;
  //   margin:10px;
  //   font-size: 1.5rem;
`;

//Code
const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <Fragment>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogoWrapper>
              <NavLogoLinkWrapper to="/" onClick={toggleHome}>
                <NavLogoSanaTuMente alt="logo home sana tu mente" src={LogoSanaTuMente} />
              </NavLogoLinkWrapper>
            </NavLogoWrapper>
            {/*<NavLogo to="/" onClick={toggleHome}>
	      PsicologaEnLinea
	    </NavLogo>*/}
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
		<NavLinks
		  href="#about"
                  to="about"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  ¿Qué es Sana tu Mente?
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
		  href="#profile"
                  to="profile"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Perfil
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
		  href="#services"
                  to="services"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Servicios
                </NavLinks>
              </NavItem>
	      {/*<NavItem>
                <NavLinks
                  to="blog"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Blog
                </NavLinks>
	      </NavItem>*/}
              <NavItem>
                <NavLinks
		  href="#contact"
                  to="contact"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Contacto
                </NavLinks>
              </NavItem>
              {/*<NavItem>
      <NavLinks to="signup">Sign Up</NavLinks> 
		</NavItem>*/}
              <NavItem>
                <NavLinks
		  href="#faq"
                  to="faq"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  FAQ
                </NavLinks>
              </NavItem>
            </NavMenu>
            {/*  <NavBtn>
              <NavBtnLink to="/signin">Sign In</NavBtnLink>
	    </NavBtn>*/}
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </Fragment>
  );
};

export default Navbar;
