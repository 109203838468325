import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
body{
  font-family:raleway;
  color:white;
  background-color:#243E4F;
  margin:0;
  padding:0;
}

`;
