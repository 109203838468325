import React, {useState, Fragment } from "react";
import styled from "styled-components";
import Error404Img from "../images/STMError404.svg";
import { Link as LinkR } from "react-router-dom";

//Styles
const PageNotFoundContainer = styled.div`
    -youbkit-touch-callout: none; /*iOS Safari*/
    -youbkit-user-select: none;   /* Chrome 6.0+, Safari 3.1+, Edge y Opera 15+ */
    -moz-user-select: none;    /*Firefox*/
    -ms-user-select: none;        /* IE 10+ y Edge */
    user-select: none;            /* Versión sin prefijo,
                                actualmente compatible con Chrome y Opera */
  color: #fff;
  background:#fff;
  width:100%;
  height:100vh;



`;

const PageNotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  height: 100vh;
  width: 100%;
  margin: 0;
  justify-content: center;
  align-items: center;


`;


const TextWrapper = styled.div`
  max-width: 480px;
  padding-top: 0;
padding-bottom: 60px;
color: #010606;
text-align: center;
`;

const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48;
  line-height: 1.1;
  font-weight: 600;
color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};
text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;



const ImgWrap = styled.div`
  max-width: 555px;
  margin-top:20px;
`;

const Img = styled.img`
  width: 100%;
  object-fit: contain;
  margin: 0;
  padding-right: 0;
  pointer-events: none;
`;

const BtnWrapper = styled.div`
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const Button = styled(LinkR)`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "#01BF71" : "#010606")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#010606" : "#fff")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
transition: all 0.2s ease-in-out;
text-align: center;
text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#fff" : "#01BF71")};
  }
`;

//Code
const PageNotFound = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <Fragment>
      <PageNotFoundContainer lightBg={true} id="Error404">
        <PageNotFoundWrapper>
                     <ImgWrap>
                {/* <Img
                  src={require("../images/svg-1.svg").default}
                  alt="img-about"
		/>*/}
                <Img src={Error404Img} />
		     </ImgWrap>
		     <Heading>Página no encontrada</Heading>
	  <TextWrapper>La página que buscas no existe, pero siempre puedes regresar a Sana tu Mente y encontrar lo que buscas</TextWrapper>
        <BtnWrapper>
          <Button
            to="/"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
          >
	    Regresa a<br />Sana tu Mente
          </Button>
        </BtnWrapper>
        </PageNotFoundWrapper>
      </PageNotFoundContainer>
    </Fragment>
  );
};

export default PageNotFound;
