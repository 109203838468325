import React, { Fragment } from "react";
import styled from "styled-components";
import AboutImg from "../images/STMAbout.svg";

//Styles
const InfoContainer = styled.div`
    -youbkit-touch-callout: none; /*iOS Safari*/
    -youbkit-user-select: none;   /* Chrome 6.0+, Safari 3.1+, Edge y Opera 15+ */
    -moz-user-select: none;    /*Firefox*/
    -ms-user-select: none;        /* IE 10+ y Edge */
    user-select: none;            /* Versión sin prefijo,
                                actualmente compatible con Chrome y Opera */
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#fff" : "#8c8c8c")};

  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

const InfoWrapper = styled.div`
  display: grid;
  flex-wrap: wrap;
  z-index: 1;
  height: 100vh;
  // height:860px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;

 @media screen and (max-width: 1366px) {
    height: auto;
  }

  @media screen and (max-width: 768px) {
    height: auto;
  }
`;

const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
`;

const Heading = styled.h1`
  margin-bottom: 24px;
  padding-top:24px;
  font-size: 48;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

const TextContent = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
`;

const ImgWrap = styled.div`
  max-width: 555px;
  height: 50%;
`;

const Img = styled.img`
  width: 100%;
  object-fit: contain;
  margin: 0 0 10px 0;
  padding-right: 0;
  pointer-events: none;
`;

//Code
const AboutSection = () => {
  return (
    <Fragment>
      <InfoContainer lightBg={true} id="about">
        <InfoWrapper>
          <InfoRow imgStart={true}>
            <Column1>
              <TextWrapper>
                <Heading lightText={false}>¿Qué es Sana tu Mente?</Heading>
                <TextContent darkText={true}>
                  Sana tu Mente es un espacio virtual, que te permite conectarte
                  contigo mismo por medio de la expresión y exteriorización de
                  lo que hay en tu interior, podrás llegar a comprenderte,
                  perdonar, reconciliarte, mejorar y sanar. Desde donde estés
                  con solo tener conexión a internet, aquí podrás tener mi
                  escucha y orientación terapéutica, para generar herramientas y
                  estrategias que te lleven a cambios positivos, con el fin de
                  afrontar de manera asertiva situaciones y conflictos en el día
                  a día, que te lleven a tomar mejores decisiones.
                </TextContent>
                <Heading lightText={false}> ¿Por qué sanar tu mente?</Heading>
                <TextContent darkText={true}>
                  Tu mente es tan importante como tu cuerpo, por ello es
                  necesario prestar atención a tu estado mental, ser consciente
                  de tus pensamientos, tus emociones y cómo te comportas, te
                  permiten tener bienestar mental; esto implica estar alejado de
                  trastornos y mantener un estado de tranquilidad contigo mismo
                  y tu entorno. En ocasiones requerimos de ayuda para trascender
                  en el manejo de situaciones que enfrentamos en la vida, para
                  ello Sana tu Mente te brinda los espacios terapéuticos, con
                  acompañamiento de una profesional con amplia experiencia en el
                  manejo de diagnósticos mentales y retos personales, que te
                  ayudará a mantener una mente saludable.
                </TextContent>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                {/* <Img
                  src={require("../images/svg-1.svg").default}
                  alt="img-about"
		/>*/}
                <Img src={AboutImg} alt={"about-image"} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </Fragment>
  );
};

export default AboutSection;
