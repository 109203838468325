import React, { Fragment } from "react";
import styled from "styled-components";
import Anxiety from "../images/STMAnsiedad.svg";
import Depression from "../images/STMDepresion.svg";
import Stress from "../images/STMEstres.svg";
import Fear from "../images/STMMiedo.svg";
import PersonalityDisorder from "../images/STMTrastornoDePersonalidad.svg";
import Addiction from "../images/STMAdiccion.svg";
import MoodDisorder from "../images/STMTranstornoAnimo.svg";
import Mourning from "../images/STMDuelo.svg";
import EmotionalDependence from "../images/STMDependenciaEmocional.svg";
import EatingDisorder from "../images/STMDesordenAlimenticio.svg";
import PsychosomaticDisorder from "../images/STMTranstornoPsicosomatico.svg";
import LowSelfEsteem from "../images/STMBajaAutoestima.svg";
import Individual from "../images/STMIndividual.svg";
import Couple from "../images/STMPareja.svg";
import Family from "../images/STMFamilia.svg";

//Styles

const ServicesContainer = styled.div`
    -youbkit-touch-callout: none; /*iOS Safari*/
    -youbkit-user-select: none;   /* Chrome 6.0+, Safari 3.1+, Edge y Opera 15+ */
    -moz-user-select: none;    /*Firefox*/
    -ms-user-select: none;        /* IE 10+ y Edge */
    user-select: none;            /* Versión sin prefijo,
                                actualmente compatible con Chrome y Opera */
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#fff" : "#8c8c8c")};

  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

const InfoServicesContainer = styled.div`
  // height:800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ lightBg }) => (lightBg ? "#fff" : "#8c8c8c")};

  // @media screen and (max-width: 768px) {
  //  height: 1100px;
  // }

  // @media screen and (max-width: 480px) {
  //  height: 1300px;
  // }
`;

const CasesWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

const CasesItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
`;

const CaseTitle = styled.div`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 10px;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

const CaseImg = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
  pointer-events:none;
`;

const CaseP = styled.p`
  font-size: 18px;
  line-height: 24px;
  padding: 0 15px;
  color: black;
  font-size: 1rem;
  text-align: center;
`;

const CaseH1 = styled.h1`
  font-size: 2.5rem;
  padding: 24px;
  margin-bottom: 64px;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

const ServicesWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

const ServicesCard = styled.div`
  background: ${({ lightBg }) => (lightBg ? "#fff" : "#8c8c8c")};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 290px;
  height: 340px;
  align-items: center;
  border-radius: 10px;
  //  max-height: 340px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

const ServicesIcon = styled.img`
  height: 160px;
  width: 160px;
margin-bottom: 10px;
pointer-events: none;
`;

const ServicesH1 = styled.h1`
  padding: 24px;
  font-size: 2.5rem;
  margin-bottom: 64px;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

const ServicesH2 = styled.h2`
  font-size: 1rem;
  color: black;
  margin-bottom: 10px;
  text-align: center;
`;

const ServicesP = styled.p`
  display: block;
  color: black;
  font-size: 1rem;
  text-align: center;
`;

//Code
const Services = () => {
  return (
    <Fragment>
      <ServicesContainer lightBg={true} id="services">
        <InfoServicesContainer lightBg={true}>
          <ServicesH1 lightText={false}>Servicios</ServicesH1>
          <CasesWrapper>
            <CasesItem>
              <CaseTitle>Terapia Individual</CaseTitle>
              <CaseImg src={Individual} alt={"servicio-individual"} />
              <CaseP>
                Toma el control de tu vida y enfrenta las situaciones del día a
                día, entendiendo tus emociones y pensamientos.
              </CaseP>
            </CasesItem>
            <CasesItem>
              <CaseTitle>Terapia de pareja</CaseTitle>
              <CaseImg src={Couple} alt={"servicio-pareja"} />
              <CaseP>
                Fortalece tu vínculo, mejora la comunicación y resuelve los
                conflictos en tu relación sentimental.
              </CaseP>
            </CasesItem>
            <CasesItem>
              <CaseTitle>Terapia familiar</CaseTitle>
              <CaseImg src={Family} alt={"servicio-familia"} />
              <CaseP>
                Modifica tus dinámicas, restablece el equilibrio en tu hogar y
                logra cambios positivos en tu familia.
              </CaseP>
            </CasesItem>
          </CasesWrapper>
          <CaseH1 lightText={false}>
            Tengo experiencia para orientarte en:
          </CaseH1>
          <ServicesWrapper>
            <ServicesCard lightBg={false}>
              <ServicesIcon src={PersonalityDisorder} alt={"trastorno-de-personalidad"} />
              <ServicesH2>Trastorno de personalidad</ServicesH2>
              <ServicesP>
                Rasgos que dificultan la adaptación de las personas en su
                interacción con el entorno.
              </ServicesP>
            </ServicesCard>
            <ServicesCard lightBg={false}>
              <ServicesIcon src={MoodDisorder} alt={"trastorno-del-animo"} />
              <ServicesH2>Trastorno del ánimo</ServicesH2>
              <ServicesP>
                Alteraciones emocionales que consisten en periodos prolongados
                de tristeza o euforia excesiva.
              </ServicesP>
            </ServicesCard>
            <ServicesCard lightBg={false}>
              <ServicesIcon src={Addiction} alt={"adiccion"} />
              <ServicesH2>Adicción</ServicesH2>
              <ServicesP>
                Enfermedad del cerebro, en la cual, se busca alivio a través del
                consumo de sustancias o actividades nocivas, generando
                incapacidad para controlarse, disfunción emocional y alteración
                en la calidad de vida.
              </ServicesP>
            </ServicesCard>
            <ServicesCard lightBg={false}>
              <ServicesIcon src={Mourning} alt={"duelo"} />
              <ServicesH2>Duelo</ServicesH2>
              <ServicesP>
                Reacción psicológica ante una perdida, ausencia, abandono o
                muerte.
              </ServicesP>
            </ServicesCard>
            <ServicesCard lightBg={false}>
              <ServicesIcon src={Fear} alt={"fobias-y-miedos"}/>
              <ServicesH2>Fobias y miedos</ServicesH2>
              <ServicesP>
                El miedo es una emoción natural, cuya función es prepararnos
                para la supervivencia en situaciones amenazantes, que se puede
                volver un problema si te limita en tu día a día o convertir en
                fobia cuando es irracional.
              </ServicesP>
            </ServicesCard>
            <ServicesCard lightBg={false}>
              <ServicesIcon src={EmotionalDependence} alt={"dependencia-emocional"} />
              <ServicesH2>Dependencia emocional</ServicesH2>
              <ServicesP>
                Vinculación afectiva excesiva, llegando a generar un trastorno
                de ansiedad ante la idea de abandono.
              </ServicesP>
            </ServicesCard>
            <ServicesCard lightBg={false}>
              <ServicesIcon src={EatingDisorder} alt={"trastorno-de-alimentacion"}/>
              <ServicesH2>Trastornos de alimentación</ServicesH2>
              <ServicesP>
                Enfermedades psicológicas que generan hábitos alimenticios poco
                saludables llegando a causar problemas mentales y físicos
                graves.
              </ServicesP>
            </ServicesCard>
            <ServicesCard lightBg={false}>
              <ServicesIcon src={PsychosomaticDisorder} alt={"trastorno-psicosomatico"} />
              <ServicesH2>Trastorno psicosomático</ServicesH2>
              <ServicesP>
                Síntomas físicos que se desencadenan por razones psicológicas.
              </ServicesP>
            </ServicesCard>
            <ServicesCard lightBg={false}>
              <ServicesIcon src={Anxiety} alt={"ansiedad"} />
              <ServicesH2>Ansiedad</ServicesH2>
              <ServicesP>
                Respuesta emocional ante un estímulo de resultado incierto, que
                nos prepara para actuar, se convierte en trastorno cuando esta
                reacción se activa en situaciones no amenazantes y llega a
                interferir en la vida diaria.
              </ServicesP>
            </ServicesCard>
            <ServicesCard lightBg={false}>
              <ServicesIcon src={Depression} alt={"depresion"}/>
              <ServicesH2>Depresión</ServicesH2>
              <ServicesP>
                Trastorno mental que se caracteriza por un bajo estado de ánimo,
                sentimientos de tristeza y alteración en el comportamiento.
              </ServicesP>
            </ServicesCard>
            <ServicesCard lightBg={false}>
              <ServicesIcon src={LowSelfEsteem} alt={"baja-autoestima-inseguridad"}/>
              <ServicesH2>Baja autoestima e inseguridad</ServicesH2>
              <ServicesP>
                Evaluación negativa que no permite a la persona sentirse valiosa
                o tener un juicio objetivo sobre ella misma y sus capacidades,
                por lo cual, la persona se impulsa a requerir una evaluación
                externa para poder valorarse.
              </ServicesP>
            </ServicesCard>
            <ServicesCard lightBg={false}>
              <ServicesIcon src={Stress} alt={"estres"} />
              <ServicesH2>Estrés</ServicesH2>
              <ServicesP>
                Respuesta física o mental ante un estimulo externo, que genera
                una sobrecarga emocional y puede influir en el bienestar de la
                persona.
              </ServicesP>
            </ServicesCard>
          </ServicesWrapper>
        </InfoServicesContainer>
      </ServicesContainer>
    </Fragment>
  );
};

export default Services;
